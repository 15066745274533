import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import {
  addLocalOffsetToTimestamp,
  getLocalDateString,
  getStartOfDayTimestamp,
  getStates,
} from "../../utils";
import { PatientReportData } from "../../ts/types";
import { editOrder } from "../../services/orders";
import { editPatient } from "../../services/patients";
import { InfoQuestionType, InsuranceStatus } from "../../ts/enums";
import { fetchInfoQuestions } from "../../services/masters";
import Modal, { ModalBody, ModalFooter } from "../../components/Modal/Modal";
import classes from "./EditPatient.module.css";
import Input from "../../components/Inputs/Input";
import SelectButton from "../../components/Buttons/SelectButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Select from "../../components/Inputs/Select";
import InputLabel from "../../components/Inputs/InputLabel";
import Patient from "../../ts/models/Patient";
import Order, { InfoQuestionResponse } from "../../ts/models/Order";
import InfoQuestion from "../../ts/models/InfoQuestion";

const getInitialFieldErrors = () => {
  let infoQuestionsErrors: boolean[] = [];
  return {
    firstName: false,
    lastName: false,
    regEmail: false,
    regPhoneNo: false,
    address: false,
    city: false,
    zipCode: false,
    dob: false,
    providerName: false,
    policyNumber: false,
    primaryMemberDob: false,
    primaryMemberName: false,
    providerName2: false,
    policyNumber2: false,
    infoQuestions: infoQuestionsErrors,
  };
};

type Props = {
  patientReportData: PatientReportData;
  onClose: (
    isUpdated: boolean,
    patientData?: Patient,
    orderData?: Order
  ) => void;
};

const EditCheckInList: React.FC<Props> = ({ onClose, patientReportData }) => {
  const { genders, ethnicGroups, races } = useSelector(
    (state: RootState) => state.masters
  );
  const pods = useSelector((state: RootState) => state.pods.pods);

  const activeGenders = genders.filter((gender) => {
    return gender.isActive;
  });
  const activeEthnicGroups = ethnicGroups.filter((ethnicGroup) => {
    return ethnicGroup.isActive;
  });
  const activeRaces = races.filter((race) => {
    return race.isActive;
  });

  const [patient, setPatient] = useState(patientReportData.patient);
  const [orderData, setOrderData] = useState(patientReportData.order);
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [insuranceOpt, setInsuranceOpt] = useState(
    patient.haveInsurance ? InsuranceStatus.Yes : InsuranceStatus.No
  );
  const [primaryOpt, setPrimaryOpt] = useState(
    patient.isPrimaryMember ? InsuranceStatus.Yes : InsuranceStatus.No
  );
  const [secondaryInsuranceOpt, setSecondaryInsuranceOpt] = useState(
    patient?.haveSecondaryInsurance ? InsuranceStatus.Yes : InsuranceStatus.No
  );

  const [infoQuestionData, setInfoQuestionData] = useState<InfoQuestion[]>([]);
  const [infoQuestionResponses, setInfoQuestionResponses] = useState<
    InfoQuestionResponse[]
  >([]);
  const pod = pods.find((data) => data.id === orderData.podId);

  useEffect(() => {
    let dob = patientReportData.patient.dob;
    let primaryMemberDob = patientReportData.patient.primaryMemberDob;
    if (typeof patientReportData.patient.dob === "number") {
      dob = addLocalOffsetToTimestamp(patientReportData.patient.dob);
    }
    if (typeof patientReportData.patient.primaryMemberDob === "number") {
      primaryMemberDob = addLocalOffsetToTimestamp(
        patientReportData.patient.primaryMemberDob
      );
    }
    setPatient((prevState) => {
      const newState = { ...prevState, dob, primaryMemberDob };
      return newState;
    });
  }, [patientReportData]);

  const isDOBNotInRange = (dob: string | number) => {
    if (!dob) {
      return false;
    }

    if (typeof dob === "number") {
      if (
        dob < new Date("1900-01-01").getTime() ||
        dob > new Date().getTime()
      ) {
        return true;
      }
    }
    return false;
  };

  const getDOBInvalidText = (dob: string | number) => {
    if (!dob) {
      // "" or NaN
      return "This field is required";
    }

    if (isDOBNotInRange(dob)) {
      return "Must be 01/01/1900 or later and no later than today";
    }

    return "";
  };

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!patient.firstName) {
      fieldErrors.firstName = true;
    }
    if (!patient.lastName) {
      fieldErrors.lastName = true;
    }
    if (!orderData.regPhoneNo || orderData.regPhoneNo.length !== 10) {
      fieldErrors.regPhoneNo = true;
    }
    if (!patient.address) {
      fieldErrors.address = true;
    }
    if (!patient.city) {
      fieldErrors.city = true;
    }
    if (!patient.zipCode) {
      fieldErrors.zipCode = true;
    }
    if (!patient.dob) {
      // "" or NaN
      fieldErrors.dob = true;
    } else if (isDOBNotInRange(patient.dob)) {
      fieldErrors.dob = true;
    }
    if (patient.haveInsurance) {
      if (!patient.insuranceDetails.providerName) {
        fieldErrors.providerName = true;
      }
      if (!patient.insuranceDetails.policyNumber) {
        fieldErrors.policyNumber = true;
      }
      if (patient.haveSecondaryInsurance) {
        if (!patient.secondaryInsuranceDetails.providerName) {
          fieldErrors.providerName2 = true;
        }
        if (!patient.secondaryInsuranceDetails.policyNumber) {
          fieldErrors.policyNumber2 = true;
        }
      }
      if (!patient.isPrimaryMember) {
        if (!patient.primaryMemberName) {
          fieldErrors.primaryMemberName = true;
        }
        if (!patient.primaryMemberDob) {
          // "" or NaN
          fieldErrors.primaryMemberDob = true;
        } else if (isDOBNotInRange(patient.primaryMemberDob)) {
          fieldErrors.primaryMemberDob = true;
        }
      }
    }
    infoQuestionData.forEach((infoQuestion, idx) => {
      const questionResponse = getQuestionResponse(infoQuestion.logicQuestion);
      if (
        infoQuestion.logicQuestion &&
        infoQuestion.logicQuestionResponse &&
        infoQuestion.logicQuestionResponse === questionResponse &&
        (!infoQuestionResponses[idx] ||
          !infoQuestionResponses[idx]?.responseText)
      ) {
        fieldErrors.infoQuestions[idx] = true;
      } else if (
        !infoQuestion.logicQuestion &&
        (!infoQuestionResponses[idx] ||
          !infoQuestionResponses[idx]?.responseText)
      ) {
        fieldErrors.infoQuestions[idx] = true;
      } else {
        fieldErrors.infoQuestions[idx] = false;
      }
    });

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => {
      if (typeof value === "boolean") {
        return value === false;
      } else {
        return value.every((subValue) => subValue === false);
      }
    });
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors, idx?: number) => {
    if (key === "infoQuestions") {
      if (idx) {
        setFieldErrors((prevState) => {
          const newState = { ...prevState };
          newState[key][idx] = false;
          return newState;
        });
      }
    } else {
      if (fieldErrors[key])
        setFieldErrors((prevState) => {
          const newState = { ...prevState };
          newState[key] = false;
          return newState;
        });
    }
  };

  const getUpdatedPatientData = () => {
    const newPatientData: any = {};

    if (
      patient.firstName !== patientReportData.patient.firstName ||
      patient.middleName !== patientReportData.patient.middleName ||
      patient.lastName !== patientReportData.patient.lastName
    ) {
      newPatientData.firstName = patient.firstName.trim();
      newPatientData.middleName = patient.middleName.trim();
      newPatientData.lastName = patient.lastName.trim();
    }
    let dob = patient.dob;
    if (typeof dob === "number") {
      dob = dob - new Date(dob).getTimezoneOffset() * 60000; // Get timezone offset on the same date to take care of DST
    }
    if (dob !== patientReportData.patient.dob) {
      newPatientData.dob = dob;
    }
    if (patient.gender !== patientReportData.patient.gender) {
      newPatientData.gender = patient.gender;
    }
    if (patient.race !== patientReportData.patient.race) {
      newPatientData.race = patient.race;
    }
    if (patient.ethnicity !== patientReportData.patient.ethnicity) {
      newPatientData.ethnicity = patient.ethnicity;
    }
    if (patient.address !== patientReportData.patient.address) {
      newPatientData.address = patient.address;
    }
    if (patient.city !== patientReportData.patient.city) {
      newPatientData.city = patient.city;
    }
    if (patient.zipCode !== patientReportData.patient.zipCode) {
      newPatientData.zipCode = patient.zipCode;
    }
    if (patient.country !== patientReportData.patient.country) {
      newPatientData.country = patient.country;
    }
    if (patient.state !== patientReportData.patient.state) {
      newPatientData.state = patient.state;
    }
    if (patient.haveInsurance !== patientReportData.patient.haveInsurance) {
      newPatientData.haveInsurance = patient.haveInsurance;
    }
    if (
      patient.insuranceDetails.groupNumber !==
        patientReportData.patient.insuranceDetails.groupNumber ||
      patient.insuranceDetails.policyNumber !==
        patientReportData.patient.insuranceDetails.policyNumber ||
      patient.insuranceDetails.providerName !==
        patientReportData.patient.insuranceDetails.providerName
    ) {
      newPatientData.insuranceDetails = {
        groupNumber: patient.insuranceDetails.groupNumber,
        policyNumber: patient.insuranceDetails.policyNumber,
        providerName: patient.insuranceDetails.providerName,
      };
    }
    if (patient.isPrimaryMember !== patientReportData.patient.isPrimaryMember) {
      newPatientData.isPrimaryMember = patient.isPrimaryMember;
    }
    if (
      patient.primaryMemberName !== patientReportData.patient.primaryMemberName
    ) {
      newPatientData.primaryMemberName = patient.primaryMemberName;
    }
    let primaryMemberDob = patient.primaryMemberDob;
    if (typeof primaryMemberDob === "number") {
      primaryMemberDob =
        primaryMemberDob -
        new Date(primaryMemberDob).getTimezoneOffset() * 60000; // Get timezone offset on the same date to take care of DST
    }
    if (primaryMemberDob !== patientReportData.patient.primaryMemberDob) {
      newPatientData.primaryMemberDob = primaryMemberDob;
    }
    if (
      patient.haveSecondaryInsurance !==
      patientReportData.patient.haveSecondaryInsurance
    ) {
      newPatientData.haveSecondaryInsurance = patient.haveSecondaryInsurance;
    }
    if (
      patient.secondaryInsuranceDetails.groupNumber !==
        patientReportData?.patient?.secondaryInsuranceDetails?.groupNumber ||
      patient.secondaryInsuranceDetails.policyNumber !==
        patientReportData?.patient?.secondaryInsuranceDetails?.policyNumber ||
      patient.secondaryInsuranceDetails.providerName !==
        patientReportData?.patient?.secondaryInsuranceDetails?.providerName
    ) {
      newPatientData.secondaryInsuranceDetails = {
        groupNumber: patient.secondaryInsuranceDetails.groupNumber,
        policyNumber: patient.secondaryInsuranceDetails.policyNumber,
        providerName: patient.secondaryInsuranceDetails.providerName,
      };
    }

    if (Object.keys(newPatientData).length > 0) {
      newPatientData.id = patientReportData.patient.id;
    }
    return newPatientData;
  };

  const isUpdatedOrderInfoQuestionData = () => {
    return (
      infoQuestionResponses?.some((info) =>
        orderData?.infoQuestionResponses?.some(
          (infoQuestion) =>
            infoQuestion?.questionId === info?.questionId &&
            infoQuestion?.responseText !== info?.responseText
        )
      ) ?? false
    );
  };

  const editPatientReport = async () => {
    if (!isFieldsValid()) {
      return;
    }

    const patientData = getUpdatedPatientData();
    const infoQuestionDataChanged = isUpdatedOrderInfoQuestionData();
    const patientDataChanged = Object.entries(patientData).length !== 0;
    const regPhoneChanged =
      orderData.regPhoneNo !== patientReportData.order.regPhoneNo;

    if (!patientDataChanged && !regPhoneChanged && !infoQuestionDataChanged) {
      onClose(false);
      return;
    }

    try {
      let promises = [];
      if (patientDataChanged) {
        promises.push(editPatient(patientData));
      }

      if (regPhoneChanged || infoQuestionDataChanged) {
        if (infoQuestionDataChanged && orderData?.infoQuestionResponses) {
          orderData.infoQuestionResponses = infoQuestionResponses.filter(
            (res) => res !== undefined
          );
        }
        promises.push(editOrder(orderData));
      }

      const results = await Promise.all(promises);
      const updateSuccess = results.every((res) => res.status === 200);

      if (updateSuccess) {
        onClose(true, patient, orderData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const insuranceOptions = [InsuranceStatus.Yes, InsuranceStatus.No];

  const fetchInfoQuestionData = async () => {
    if (pod?.infoQuestions) {
      const filter = {
        where: {
          id: { $in: pod?.infoQuestions },
        },
      };
      try {
        const infoQuestionRes = await fetchInfoQuestions(filter);
        if (infoQuestionRes?.status === 200) {
          let infoQuestion: any[] = [];
          let infoQuestionMap = new Map<string, InfoQuestion>();
          infoQuestionRes.data.forEach((infoQuestion: InfoQuestion) => {
            infoQuestionMap.set(infoQuestion.id, infoQuestion);
          });
          infoQuestion = pod?.infoQuestions.map((questionId) =>
            infoQuestionMap.get(questionId)
          );

          setInfoQuestionData(infoQuestion);

          const questionResponse: any =
            pod?.infoQuestions?.map(
              (questionId) =>
                orderData.infoQuestionResponses?.find(
                  (response) => response.questionId === questionId
                ) ?? undefined
            ) || [];

          setInfoQuestionResponses(questionResponse);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (orderData.infoQuestionResponses.length > 0) {
      fetchInfoQuestionData();
    }
  }, []);

  const infoQuestionResponseHandler = (index: number, responseText: string) => {
    setInfoQuestionResponses((prevState) => {
      let newState = [...prevState];
      newState[index] = {
        questionId: infoQuestionData[index].id,
        responseText,
      };
      if (
        infoQuestionData.some(
          (question) =>
            question.id === infoQuestionData[index].id &&
            question.logicQuestionResponse !== responseText
        )
      ) {
        clearDependentAnswers(newState, infoQuestionData[index].id);
      }
      return newState;
    });

    clearFieldErrors("infoQuestions", index);
  };

  const clearDependentAnswers = (
    response: InfoQuestionResponse[],
    questionId: string
  ) => {
    const dependentQuestions = infoQuestionData.filter(
      (q) => q.logicQuestion === questionId
    );
    let updatedResponse = [...response];

    dependentQuestions.forEach((question) => {
      if (question?.id) {
        const index = updatedResponse?.findIndex(
          (e) => e?.questionId === question.id
        );
        delete updatedResponse[index];
        if (question.logicQuestion) {
          updatedResponse = clearDependentAnswers(updatedResponse, question.id);
        }
      }
    });

    setInfoQuestionResponses(updatedResponse);
    return updatedResponse;
  };

  const getQuestionResponse = (questionId: string) => {
    if (infoQuestionResponses?.length) {
      const questionResponse =
        infoQuestionResponses?.find(
          (question) => question?.questionId === questionId
        )?.responseText || "";
      return questionResponse;
    }
  };

  const isQuestionVisible = (infoQuestion: InfoQuestion) => {
    const questionResponse = getQuestionResponse(infoQuestion.logicQuestion);
    if (
      infoQuestion.logicQuestion &&
      questionResponse !== infoQuestion.logicQuestionResponse
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Modal
        className={classes.heading}
        title={<>Edit Patient Details </>}
        onClose={() => onClose(false)}
        style={{ width: "50%", maxHeight: "80%" }}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <div className={classes.patientInfoHeading}>
                Patient's Information
              </div>
              <div className={classes.nameContainer}>
                <div>
                  <Input
                    placeholder={"First Name"}
                    value={patient.firstName}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, firstName: e.target.value };
                      });
                      clearFieldErrors("firstName");
                    }}
                    isInvalid={fieldErrors.firstName}
                    className={classes.input}
                    name={"First Name"}
                  />
                </div>
                <div>
                  <Input
                    placeholder={"Middle Name"}
                    value={patient.middleName}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, middleName: e.target.value };
                      });
                    }}
                    className={classes.input}
                    name={"Middle Name"}
                  />
                </div>
                <div>
                  <Input
                    placeholder={"Last Name"}
                    value={patient.lastName}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, lastName: e.target.value };
                      });
                      clearFieldErrors("lastName");
                    }}
                    isInvalid={fieldErrors.lastName}
                    className={classes.input}
                    name={"Last Name"}
                  />
                </div>
              </div>
              <div className={classes.dobContainer}>
                <Input
                  type='date'
                  placeholder=''
                  min='1900-01-01'
                  max={getLocalDateString(new Date().getTime())}
                  value={
                    typeof patient.dob === "number"
                      ? getLocalDateString(patient.dob)
                      : ""
                  }
                  onChange={(e) => {
                    setPatient((prevState) => {
                      return {
                        ...prevState,
                        dob: getStartOfDayTimestamp(e.target.value),
                      };
                    });
                    clearFieldErrors("dob");
                  }}
                  isInvalid={fieldErrors.dob}
                  invalidText={getDOBInvalidText(patient.dob)}
                  name={"Patient's Date Of Birth"}
                />
              </div>
              <div className={classes.genderContainer}>
                <InputLabel
                  label='Gender'
                  invalidText='This field is required'
                />
                <div className={classes.gender}>
                  {activeGenders.map((gender, idx) => (
                    <SelectButton
                      onClick={() => {
                        setPatient((state) => ({
                          ...state,
                          gender: gender.id,
                        }));
                      }}
                      key={idx}
                      name={gender.description}
                      active={patient.gender === gender.id}
                    />
                  ))}
                </div>
              </div>
              <div className={classes.container}>
                <div>
                  <Select
                    label={"Race"}
                    htmlFor='race'
                    options={activeRaces}
                    value={patient.race}
                    defaultOption='Select Race'
                    nameExtractor={"description"}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, race: e.target.value };
                      });
                    }}
                  />
                </div>
                <div>
                  <Select
                    label={"Ethnicity"}
                    htmlFor='ethnicity'
                    options={activeEthnicGroups}
                    value={patient.ethnicity}
                    defaultOption='Select Ethnicity'
                    nameExtractor={"description"}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, ethnicity: e.target.value };
                      });
                    }}
                  />
                </div>
              </div>
              <div className={classes.addressContainer}>
                <Input
                  placeholder={"Enter Your Address"}
                  value={patient.address}
                  onChange={(e) => {
                    setPatient((prevState) => {
                      return { ...prevState, address: e.target.value };
                    });
                    clearFieldErrors("address");
                  }}
                  isInvalid={fieldErrors.address}
                  name={"Address"}
                />
              </div>
              <div className={classes.zipContainer}>
                <div>
                  <Input
                    className={classes.input}
                    placeholder={"Enter Zipcode"}
                    type={"number"}
                    value={patient.zipCode}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, zipCode: e.target.value };
                      });
                      clearFieldErrors("zipCode");
                    }}
                    isInvalid={fieldErrors.zipCode}
                    name={"Zip code"}
                  />
                </div>
                <div>
                  <Input
                    className={classes.input}
                    placeholder={"Enter Your City"}
                    value={patient.city}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return {
                          ...prevState,
                          city: e.target.value,
                        };
                      });
                      clearFieldErrors("city");
                    }}
                    isInvalid={fieldErrors.city}
                    name={"City"}
                  />
                </div>
                <div>
                  <Select
                    label={"Country"}
                    htmlFor='country'
                    options={[{ countryName: "USA", alphaCode: "US" }]}
                    value={patient.country}
                    nameExtractor={"countryName"}
                    valueExtractor={"alphaCode"}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, country: e.target.value };
                      });
                    }}
                  />
                </div>
                <div>
                  <Select
                    label={"State"}
                    htmlFor='state'
                    options={getStates}
                    value={patient.state}
                    nameExtractor={"stateName"}
                    valueExtractor={"alphaCode"}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return { ...prevState, state: e.target.value };
                      });
                    }}
                  />
                </div>
              </div>
              <div className={classes.medicalInsurance}>
                <InputLabel label='Does the patient have Medical Insurance?' />
                <div>
                  <div className={classes.insuranceActions}>
                    {insuranceOptions.map((option, optionIdx) => (
                      <SelectButton
                        key={optionIdx}
                        className={classes.action}
                        name={option}
                        onClick={() => {
                          setInsuranceOpt(option);
                          setPatient((prevState) => {
                            const newState = {
                              ...prevState,
                              haveInsurance:
                                option === InsuranceStatus.Yes ? true : false,
                            };
                            if (option === InsuranceStatus.No) {
                              newState.insuranceDetails = {
                                providerName: "",
                                policyNumber: "",
                                groupNumber: "",
                              };
                              newState.primaryMemberName = "";
                              newState.primaryMemberDob = "";
                              newState.secondaryInsuranceDetails = {
                                providerName: "",
                                policyNumber: "",
                                groupNumber: "",
                              };
                              newState.isPrimaryMember = false;
                              newState.haveSecondaryInsurance = false;
                              setPrimaryOpt(InsuranceStatus.No);
                              setSecondaryInsuranceOpt(InsuranceStatus.No);
                            }
                            return newState;
                          });
                        }}
                        active={insuranceOpt === option}
                      />
                    ))}
                  </div>
                </div>
                <div className={classes.inputAddress}>
                  <Input
                    inputClassName={classes.input}
                    placeholder={"Enter Insurance Company"}
                    value={patient.insuranceDetails.providerName ?? ""}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return {
                          ...prevState,
                          insuranceDetails: {
                            ...prevState.insuranceDetails,
                            providerName: e.target.value,
                          },
                        };
                      });
                      clearFieldErrors("providerName");
                    }}
                    name={"Insurance Company"}
                    disabled={!patient.haveInsurance}
                    isInvalid={
                      patient.haveInsurance ? fieldErrors.providerName : false
                    }
                  />
                </div>
                <div className={classes.inputAddress}>
                  <Input
                    inputClassName={classes.input}
                    placeholder={"Enter Policy Number"}
                    value={patient.insuranceDetails.policyNumber ?? ""}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return {
                          ...prevState,
                          insuranceDetails: {
                            ...prevState.insuranceDetails,
                            policyNumber: e.target.value,
                          },
                        };
                      });
                      clearFieldErrors("policyNumber");
                    }}
                    name={"Policy Number"}
                    disabled={!patient.haveInsurance}
                    isInvalid={
                      patient.haveInsurance ? fieldErrors.policyNumber : false
                    }
                  />
                </div>
                <div className={classes.inputAddress}>
                  <Input
                    inputClassName={classes.input}
                    placeholder={"Enter Group Number"}
                    value={patient.insuranceDetails.groupNumber ?? ""}
                    onChange={(e) => {
                      setPatient((prevState) => {
                        return {
                          ...prevState,
                          insuranceDetails: {
                            ...prevState.insuranceDetails,
                            groupNumber: e.target.value,
                          },
                        };
                      });
                    }}
                    name={"Group Number"}
                    disabled={!patient.haveInsurance}
                  />
                </div>
              </div>
              <>
                {insuranceOpt === InsuranceStatus.Yes && (
                  <>
                    <div className={classes.medicalInsurance}>
                      <InputLabel label='Is the patient the primary member in the policy?' />
                      <div>
                        <div className={classes.insuranceActions}>
                          {insuranceOptions.map((option, optionIdx) => (
                            <SelectButton
                              key={optionIdx}
                              className={classes.action}
                              name={option}
                              onClick={() => {
                                setPrimaryOpt(option);
                                setPatient((prevState) => {
                                  const newState = {
                                    ...prevState,
                                    isPrimaryMember:
                                      option === InsuranceStatus.Yes
                                        ? true
                                        : false,
                                  };
                                  if (option === InsuranceStatus.No) {
                                    newState.primaryMemberName = "";
                                    newState.primaryMemberDob = "";
                                  }
                                  return newState;
                                });
                              }}
                              active={primaryOpt === option}
                            />
                          ))}
                        </div>
                      </div>
                      {primaryOpt === InsuranceStatus.No && (
                        <>
                          <div className={classes.inputAddress}>
                            <Input
                              placeholder={"Enter Name"}
                              value={patient.primaryMemberName}
                              onChange={(e) => {
                                setPatient((prevState) => {
                                  return {
                                    ...prevState,
                                    primaryMemberName: e.target.value,
                                  };
                                });
                                clearFieldErrors("primaryMemberName");
                              }}
                              isInvalid={fieldErrors.primaryMemberName}
                              className={classes.input}
                              name={"Primary Member's Name"}
                            />
                          </div>
                          <div className={classes.inputAddress}>
                            <Input
                              type='date'
                              placeholder=''
                              min='1900-01-01'
                              max={getLocalDateString(new Date().getTime())}
                              value={
                                typeof patient.primaryMemberDob === "number"
                                  ? getLocalDateString(patient.primaryMemberDob)
                                  : ""
                              }
                              onChange={(e) => {
                                setPatient((prevState) => {
                                  return {
                                    ...prevState,
                                    primaryMemberDob: getStartOfDayTimestamp(
                                      e.target.value
                                    ),
                                  };
                                });
                                clearFieldErrors("primaryMemberDob");
                              }}
                              isInvalid={fieldErrors.primaryMemberDob}
                              invalidText={getDOBInvalidText(
                                patient.primaryMemberDob
                              )}
                              name={"Primary Member's Date of Birth"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className={classes.medicalInsurance}>
                      <InputLabel label='Does the patient have a secondary policy?' />
                      <div>
                        <div className={classes.insuranceActions}>
                          {insuranceOptions.map((option, optionIdx) => (
                            <SelectButton
                              key={optionIdx}
                              className={classes.action}
                              name={option}
                              onClick={() => {
                                setSecondaryInsuranceOpt(option);
                                setPatient((prevState) => {
                                  const newState = {
                                    ...prevState,
                                    haveSecondaryInsurance:
                                      option === InsuranceStatus.Yes
                                        ? true
                                        : false,
                                  };
                                  if (option === InsuranceStatus.No) {
                                    newState.secondaryInsuranceDetails = {
                                      providerName: "",
                                      policyNumber: "",
                                      groupNumber: "",
                                    };
                                  }
                                  return newState;
                                });
                              }}
                              active={secondaryInsuranceOpt === option}
                            />
                          ))}
                        </div>
                      </div>

                      {secondaryInsuranceOpt === InsuranceStatus.Yes && (
                        <>
                          <div className={classes.inputAddress}>
                            <Input
                              inputClassName={classes.input}
                              placeholder={"Enter Insurance Company"}
                              value={
                                patient?.secondaryInsuranceDetails
                                  ?.providerName ?? ""
                              }
                              onChange={(e) => {
                                setPatient((prevState) => {
                                  return {
                                    ...prevState,
                                    secondaryInsuranceDetails: {
                                      ...prevState.secondaryInsuranceDetails,
                                      providerName: e.target.value,
                                    },
                                  };
                                });
                                clearFieldErrors("providerName2");
                              }}
                              name={"Insurance Company (Secondary)"}
                              isInvalid={
                                patient.haveSecondaryInsurance
                                  ? fieldErrors.providerName2
                                  : false
                              }
                            />
                          </div>
                          <div className={classes.inputAddress}>
                            <Input
                              inputClassName={classes.input}
                              placeholder={"Enter Policy Number"}
                              value={
                                patient?.secondaryInsuranceDetails
                                  ?.policyNumber ?? ""
                              }
                              onChange={(e) => {
                                setPatient((prevState) => {
                                  return {
                                    ...prevState,
                                    secondaryInsuranceDetails: {
                                      ...prevState.secondaryInsuranceDetails,
                                      policyNumber: e.target.value,
                                    },
                                  };
                                });
                                clearFieldErrors("policyNumber2");
                              }}
                              name={"Policy Number (Secondary)"}
                              isInvalid={
                                patient.haveSecondaryInsurance
                                  ? fieldErrors.policyNumber2
                                  : false
                              }
                            />
                          </div>
                          <div className={classes.inputAddress}>
                            <Input
                              inputClassName={classes.input}
                              placeholder={"Enter Group Number"}
                              value={
                                patient?.secondaryInsuranceDetails
                                  ?.groupNumber ?? ""
                              }
                              onChange={(e) => {
                                setPatient((prevState) => {
                                  return {
                                    ...prevState,
                                    secondaryInsuranceDetails: {
                                      ...prevState.secondaryInsuranceDetails,
                                      groupNumber: e.target.value,
                                    },
                                  };
                                });
                              }}
                              name={"Group Number (Secondary)"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </>
              {infoQuestionData.length > 0 ? (
                <div className={classes.contactInfoHeading}>Info Questions</div>
              ) : (
                ""
              )}
              <div className={classes.infoQuestions}>
                {infoQuestionData.map((infoQuestion, infoQuestionIdx) =>
                  isQuestionVisible(infoQuestion) ? (
                    <div key={infoQuestionIdx}>
                      <div className={classes.infoQuestionOptions}>
                        {infoQuestion.questionType ===
                          InfoQuestionType.FreeText && (
                          <Input
                            inputClassName={classes.input}
                            placeholder={"Enter your response"}
                            value={
                              infoQuestionResponses[infoQuestionIdx]
                                ?.responseText || ""
                            }
                            onChange={(e) =>
                              infoQuestionResponseHandler(
                                infoQuestionIdx,
                                e.target.value
                              )
                            }
                            name={infoQuestion.question}
                            isInvalid={
                              fieldErrors.infoQuestions[infoQuestionIdx] ||
                              false
                            }
                          />
                        )}
                        {infoQuestion.questionType ===
                          InfoQuestionType.Number && (
                          <Input
                            inputClassName={classes.input}
                            placeholder={"Enter your response"}
                            type='number'
                            value={
                              infoQuestionResponses[infoQuestionIdx]
                                ?.responseText || ""
                            }
                            onChange={(e) =>
                              infoQuestionResponseHandler(
                                infoQuestionIdx,
                                e.target.value
                              )
                            }
                            name={infoQuestion.question}
                            isInvalid={
                              fieldErrors.infoQuestions[infoQuestionIdx] ||
                              false
                            }
                          />
                        )}
                        {infoQuestion.questionType ===
                          InfoQuestionType.OptionList && (
                          <Select
                            label={infoQuestion.question}
                            htmlFor='option'
                            options={infoQuestion.options.map((option) => {
                              return {
                                optionText: option,
                              };
                            })}
                            value={
                              infoQuestionResponses[infoQuestionIdx]
                                ?.responseText || ""
                            }
                            defaultOption='Select Option'
                            nameExtractor='optionText'
                            valueExtractor='optionText'
                            onChange={(e) =>
                              infoQuestionResponseHandler(
                                infoQuestionIdx,
                                e.target.value
                              )
                            }
                            isInvalid={
                              fieldErrors.infoQuestions[infoQuestionIdx] ||
                              false
                            }
                          />
                        )}
                        {infoQuestion.questionType ===
                          InfoQuestionType.Date && (
                          <Input
                            type='date'
                            placeholder=''
                            value={
                              infoQuestionResponses[infoQuestionIdx]
                                ?.responseText || ""
                            }
                            onChange={(e) =>
                              infoQuestionResponseHandler(
                                infoQuestionIdx,
                                getLocalDateString(
                                  getStartOfDayTimestamp(e.target.value)
                                )
                              )
                            }
                            name={infoQuestion.question}
                            isInvalid={
                              fieldErrors.infoQuestions[infoQuestionIdx] ||
                              false
                            }
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
              <div>
                <div className={classes.contactInfoHeading}>
                  Contact Information
                </div>
                <div className={classes.contactInformation}>
                  <InputLabel
                    label='Email Id'
                    invalidText='This field is required'
                  />
                  <div>
                    <div className={classes.email}>{orderData.regEmail}</div>
                    <div className={classes.nonEditableText}>Non Editable</div>
                  </div>
                </div>
                <div className={classes.selectContainer}>
                  <Select
                    className={classes.country}
                    label={""}
                    htmlFor='country'
                    options={[{ name: "+ USA", id: "1" }]}
                    value={"1"}
                    nameExtractor='name'
                    onChange={() => {}}
                  />
                  <Input
                    style={{ flex: 5 }}
                    type='number'
                    value={orderData.regPhoneNo}
                    onChange={(e) => {
                      setOrderData((prevState) => {
                        return { ...prevState, regPhoneNo: e.target.value };
                      });
                      clearFieldErrors("regPhoneNo");
                    }}
                    isInvalid={fieldErrors.regPhoneNo}
                    invalidText='Must be 10 digits'
                    name={"Phone Number"}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <PrimaryButton
                className={classes.actionBtn}
                name={"Save Changes"}
                onClick={editPatientReport}
                style={{ marginRight: "1rem" }}
              />
              <PrimaryButton
                className={classes.actionBtn}
                name='Cancel'
                onClick={() => onClose(false)}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default EditCheckInList;
