import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTimezoneSelect } from "react-timezone-select";

import {
  getJsonBlob,
  getClasses,
  getEndOfDayTimestamp,
  getLocalDateString,
  navigationBack,
  getLocalDateTimeString,
  getTimestampFromDateValue,
} from "../../utils";
import { AppDispatch, RootState } from "../../store";
import { Column } from "../../ts/types";
import { addPOD, editPOD } from "../../store/actions/PODActions";
import { InfoQuestionType } from "../../ts/enums";
import {
  fetchInfoQuestions,
  fetchInfoQuestionsCount,
} from "../../services/masters";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import POD from "../../ts/models/POD";
import DragDropField from "../../components/Inputs/DragDropField";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Input from "../../components/Inputs/Input";
import LinkButton from "../../components/Buttons/LinkButton";
import Table from "../../components/CustTable/Table";
import SelectItemsModal from "../../components/SelectItems/SelectItemsModal";
import classes from "./CreatePOD.module.css";
import addIcon from "../../assets/icons/add-circle.svg";
import CommonMaster from "../../ts/models/CommonMaster";
import InfoQuestion from "../../ts/models/InfoQuestion";
import FormBox from "../../components/Box/FormBox";
import HelpModal from "../../components/Help/HelpModal";
import helpIcon from "../../assets/icons/help-info.svg";
import Select from "../../components/Inputs/Select";
import InfoQuestionsOrdering from "./InfoQuestionsOrdering/InfoQuestionsOrdering";

const pageSize = 10;

const getPageCount = (totalCount: number) => {
  const count = Math.ceil(Number(totalCount) / pageSize);
  return count;
};

const BASE = "/api";

const getImageUrl = (
  imageUrl: string,
  updateTime: number,
  tenantId: string
) => {
  if (!imageUrl) {
    return "";
  }
  return `${BASE}${imageUrl}?${updateTime}&tenantId=${tenantId}`;
};

const getInitialFieldErrors = () => {
  return {
    code: false,
    name: false,
    introHeading: false,
    introContent: false,
  };
};

const infoQuestionTypes = [
  {
    name: "Option List",
    type: InfoQuestionType.OptionList,
  },
  {
    name: "Free Text",
    type: InfoQuestionType.FreeText,
  },
  {
    name: "Number",
    type: InfoQuestionType.Number,
  },
  {
    name: "Date",
    type: InfoQuestionType.Date,
  },
];

const getLocalTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp + localOffset - offset * 60000;
};

const getZoneTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp - localOffset + offset * 60000;
};

const CreatePOD = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({});

  const podToBeEdited = useSelector(
    (state: RootState) => state.pods.podToBeEdited
  );
  const allDisclaimers: CommonMaster[] = useSelector(
    (state: RootState) => state.masters.disclaimers
  );
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const [podData, setPodData] = useState<POD>(podToBeEdited);
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [logoImageFile, setLogoImageFile] = useState(null);
  const [heroImageUrl, setHeroImageUrl] = useState<any>(
    getImageUrl(podData.heroImage, podData.updateTime, resourceName)
  );
  const [logoImageUrl, setLogoImageUrl] = useState<any>(
    getImageUrl(podData.logoImage, podData.updateTime, resourceName)
  );
  const [showInfoQuestionsModal, setShowInfoQuestionsModal] = useState(false);
  const [showDisclaimersModal, setShowDisclaimersModal] = useState(false);
  const [showChangeOrderingModal, setShowChangeOrderingModal] = useState(false);
  const [selectedDisclaimerIds, setSelectedDisclaimerIds] = useState<
    Set<string>
  >(new Set<string>(new Set(podToBeEdited.disclaimers)));
  const [selectedInfoQuestionIds, setSelectedInfoQuestionIds] = useState<
    Set<string>
  >(new Set<string>(new Set(podToBeEdited.infoQuestions)));
  const [disclaimerTotalPages, setDisclaimerTotalPages] = useState(
    getPageCount(allDisclaimers.length)
  );
  const [infoQuesTotalPages, setInfoQuesTotalPages] = useState(1);
  const [filteredDisclaimers, setFilteredDisclaimers] =
    useState<CommonMaster[]>(allDisclaimers);
  const [selectedDisclaimers, setSelectedDisclaimers] = useState<
    CommonMaster[]
  >([]);
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [infoQuestions, setInfoQuestions] = useState<InfoQuestion[]>([]);
  const [selectedInfoQuestions, setSelectedInfoQuestions] = useState<
    InfoQuestion[]
  >([]);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [timezone, setTimezone] = useState<any>(getDefaultTimezone());

  function getDefaultTimezone() {
    let defaultTimeZone = parseTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    if (podToBeEdited?.landingPage?.timezoneOffset) {
      const offset = podToBeEdited.landingPage.timezoneOffset / 60;
      const timezone = timezoneOptions.find(
        (timezone) => timezone.offset === offset
      );
      if (timezone) defaultTimeZone = timezone;
    }
    return defaultTimeZone;
  }

  const activeDisclaimers = allDisclaimers.filter((disclaimer) => {
    return disclaimer.isActive;
  });

  const removeDisclaimer = (id: string) => {
    setSelectedDisclaimerIds((state) => {
      const newDisclaimerSet = new Set(state);
      newDisclaimerSet.delete(id);
      return newDisclaimerSet;
    });
  };

  const disclaimerTableColumn: Column<CommonMaster>[] = [
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
      width: "75%",
    },
    {
      header: "ACTION",
      accessor: (disclaimer) => (
        <LinkButton
          onClick={() => removeDisclaimer(disclaimer.id)}
          label='Remove'
        />
      ),
    },
  ];

  const disclaimerColumns: Column<CommonMaster>[] = [
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
      width: "75%",
    },
  ];

  const searchDisclaimersHandler = (
    offset: number = 0,
    searchText: string = ""
  ) => {
    const filteredDisclaimers = activeDisclaimers.filter(
      ({ code, description }) =>
        description.toLowerCase().includes(searchText.toLowerCase()) ||
        code.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredDisclaimers(
      filteredDisclaimers.slice(offset, offset + pageSize)
    );
    setDisclaimerTotalPages(getPageCount(filteredDisclaimers.length));
  };

  useEffect(() => {
    const getDisclaimersFromIds = () => {
      const disclaimerIds = Array.from(selectedDisclaimerIds);

      const selectedDisclaimers = allDisclaimers.filter((disclaimer) =>
        disclaimerIds.includes(disclaimer.id)
      );
      setSelectedDisclaimers(selectedDisclaimers);
    };
    getDisclaimersFromIds();
  }, [selectedDisclaimerIds]);

  const infoQuestionTableColumn: Column<InfoQuestion>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "TYPE",
      accessor: (e) =>
        infoQuestionTypes.find(
          (questionType) => questionType.type === e.questionType
        )?.name || "",
    },
    {
      header: "OPTIONS",
      accessor: (e) => (
        <div>
          {e.questionType !== InfoQuestionType.OptionList && (
            <div className={classes.questionType}>Not Applicable</div>
          )}
          {e.options.map((item, idx) => (
            <div key={idx}>
              {`${idx + 1}. `}
              {item}
            </div>
          ))}
        </div>
      ),
      width: "20%",
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <LinkButton onClick={() => removeInfoQuestion(e.id)} label='Remove' />
      ),
    },
  ];
  const removeInfoQuestion = (id: string) => {
    setSelectedInfoQuestionIds((state) => {
      const newInfoQuestionSet = new Set(state);
      newInfoQuestionSet.delete(id);
      return newInfoQuestionSet;
    });
  };

  const selectInfoQuestionsColumns: Column<InfoQuestion>[] = [
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "TYPE",
      accessor: (e) =>
        infoQuestionTypes.find(
          (questionType) => questionType.type === e.questionType
        )?.name || "",
    },
    {
      header: "OPTIONS",
      accessor: (e) => (
        <div>
          {e.questionType !== InfoQuestionType.OptionList && (
            <div className={classes.questionType}>Not Applicable</div>
          )}
          {e.options.map((item, idx) => (
            <div key={idx}>
              {`${idx + 1}. `}
              {item}
            </div>
          ))}
        </div>
      ),
      width: "20%",
    },
  ];

  const searchInfoQuestionsHandler = async (
    offset: number = 0,
    searchText: string = ""
  ) => {
    const filter = {
      limit: 10,
      offset,
      where: {
        $or: [
          {
            question: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [infoQuestionRes, countRes] = await Promise.all([
        fetchInfoQuestions(filter),
        fetchInfoQuestionsCount(filter.where),
      ]);
      if (infoQuestionRes?.status === 200) {
        setInfoQuestions(infoQuestionRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setInfoQuesTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchInfoQuestionsFromIds = async () => {
      const infoQuestionIds = Array.from(selectedInfoQuestionIds).map(
        (infoQuesId) => ({
          $oid: infoQuesId,
        })
      );

      if (infoQuestionIds?.length) {
        const filter = {
          where: {
            _id: {
              $in: infoQuestionIds,
            },
          },
        };

        try {
          const infoQuestionRes = await fetchInfoQuestions(filter);

          if (infoQuestionRes.status) {
            let infoQuestion: any[] = [];
            let infoQuestionMap = new Map<string, InfoQuestion>();
            infoQuestionRes.data.forEach((infoQuestion: InfoQuestion) => {
              infoQuestionMap.set(infoQuestion.id, infoQuestion);
            });
            infoQuestion = infoQuestionIds.map((questionId) =>
              infoQuestionMap.get(questionId.$oid)
            );
            setSelectedInfoQuestions(infoQuestion);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setSelectedInfoQuestions([]);
      }
    };

    fetchInfoQuestionsFromIds();
  }, [selectedInfoQuestionIds]);

  const handleBack = () => {
    navigationBack(location, navigate);
  };

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!podData.code) {
      fieldErrors.code = true;
    }

    if (!podData.name) {
      fieldErrors.name = true;
    }

    if (!podData.introHeading) {
      fieldErrors.introHeading = true;
    }

    if (!podData.introContent) {
      fieldErrors.introContent = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const addPODHandler = async (isEdit = false) => {
    if (!isFieldsValid()) {
      return;
    }

    const formData = new FormData();
    const timezoneOffset = timezone.offset * 60;

    const data = { ...podData };
    data.disclaimers = Array.from(selectedDisclaimerIds);
    data.infoQuestions = Array.from(selectedInfoQuestionIds);
    data.name = data.name.trim();
    data.landingPage = {
      ...data.landingPage,
      timezoneOffset,
      startTime: getZoneTime(data.landingPage.startTime, -timezoneOffset),
      endTime: getZoneTime(data.landingPage.endTime, -timezoneOffset),
    };

    formData.append("pod", getJsonBlob(data));

    if (heroImageFile) {
      formData.append("heroImage", heroImageFile);
    }

    if (logoImageFile) {
      formData.append("logoImage", logoImageFile);
    }

    if (isEdit) {
      dispatch(editPOD(formData, data.id, handleBack));
    } else {
      dispatch(addPOD(formData, handleBack));
    }
  };

  const guidelineHandler = (
    value: string,
    isHeading: boolean,
    index: number
  ) => {
    setPodData((prevState) => {
      const eventGuidelines = [...prevState.eventGuidelines];
      const eventGuideline = { ...eventGuidelines[index] };
      if (isHeading) {
        eventGuideline.heading = value;
      } else {
        eventGuideline.text = value;
      }
      eventGuidelines[index] = eventGuideline;

      return {
        ...prevState,
        eventGuidelines,
      };
    });
  };

  if (heroImageFile) {
    let reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result;
      setHeroImageUrl(imageUrl);
    };
    reader.readAsDataURL(heroImageFile);
  }

  if (logoImageFile) {
    let reader = new FileReader();
    reader.onloadend = () => {
      const imageUrl = reader.result;
      setLogoImageUrl(imageUrl);
    };
    reader.readAsDataURL(logoImageFile);
  }

  return (
    <>
      {showDisclaimersModal && (
        <SelectItemsModal
          title={"Add Disclaimers"}
          items={filteredDisclaimers}
          setItemsHandler={(items) => setSelectedDisclaimerIds(items)}
          columns={disclaimerColumns}
          onClose={() => setShowDisclaimersModal(false)}
          initialItemsSet={selectedDisclaimerIds}
          searchItemsHandler={searchDisclaimersHandler}
          noOfPages={disclaimerTotalPages}
          isPaginated
        />
      )}
      {showInfoQuestionsModal && (
        <SelectItemsModal<InfoQuestion>
          title={"Add Info Questions"}
          initialItemsSet={selectedInfoQuestionIds}
          setItemsHandler={(items) => setSelectedInfoQuestionIds(items)}
          onClose={() => setShowInfoQuestionsModal(false)}
          items={infoQuestions}
          noOfPages={infoQuesTotalPages}
          columns={selectInfoQuestionsColumns}
          searchItemsHandler={searchInfoQuestionsHandler}
          isPaginated
        />
      )}
      {showChangeOrderingModal && (
        <InfoQuestionsOrdering
          items={selectedInfoQuestions}
          onClose={() => setShowChangeOrderingModal(false)}
          setItemsHandler={(items) => setSelectedInfoQuestionIds(items)}
        />
      )}
      {showHelpModal && (
        <HelpModal
          title='Create POD'
          content={`/api/storage/cimpar/resources/help/create-pod-help.html`}
          onClose={() => setShowHelpModal(false)}
        />
      )}
      <PanelContainer
        name={`${podToBeEdited.id ? "Update" : "Create New"} POD`}
        backBtn>
        <div className={classes.helpContainer}>
          <LinkButton
            label={"Know About How To Create POD"}
            icon={<img src={helpIcon} alt='' />}
            onClick={() => setShowHelpModal(true)}
            className={classes.helpBtn}
          />
        </div>
        <PanelInnerContainer>
          <FormBox name='Basic Details'>
            <div className={classes.basicDetails}>
              <div>
                <Input
                  name='Code'
                  value={podData.code}
                  onChange={(e) => {
                    setPodData((state) => ({ ...state, code: e.target.value }));
                    clearFieldErrors("code");
                  }}
                  isInvalid={fieldErrors.code}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Name'
                  value={podData.name}
                  onChange={(e) => {
                    setPodData((state) => ({ ...state, name: e.target.value }));
                    clearFieldErrors("name");
                  }}
                  isInvalid={fieldErrors.name}
                  className={classes.input}
                />
              </div>
              <Input
                name='Address'
                value={podData.address}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    address: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='City'
                value={podData.city}
                onChange={(e) =>
                  setPodData((state) => ({ ...state, city: e.target.value }))
                }
                className={classes.input}
              />
              <Input
                name='State'
                value={podData.state}
                onChange={(e) =>
                  setPodData((state) => ({ ...state, state: e.target.value }))
                }
                className={classes.input}
              />
              <Input
                name='Zip Code'
                type='number'
                value={podData.zipCode}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    zipCode: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='Contract Number'
                value={podData.contractNumber}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    contractNumber: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='Contract Expiry'
                value={getLocalDateString(podData.contractExpiry)}
                type='date'
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    contractExpiry: getEndOfDayTimestamp(e.target.value),
                  }))
                }
                className={classes.input}
              />
              <Input
                name='POC 1 Name'
                value={podData.poc1Name}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    poc1Name: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='POC 1 Email'
                value={podData.poc1Email}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    poc1Email: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='POC 1 PhoneNo'
                type='number'
                value={podData.poc1PhoneNo}
                onChange={(e) => {
                  setPodData((state) => ({
                    ...state,
                    poc1PhoneNo: e.target.value,
                  }));
                }}
                className={classes.input}
              />
            </div>
            <div className={classes.basicDetails}>
              <Input
                name='POC 2 Name'
                value={podData.poc2Name}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    poc2Name: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='POC 2 Email'
                value={podData.poc2Email}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    poc2Email: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='POC 2 PhoneNo'
                type='number'
                value={podData.poc2PhoneNo}
                onChange={(e) =>
                  setPodData((state) => ({
                    ...state,
                    poc2PhoneNo: e.target.value,
                  }))
                }
                className={classes.input}
              />
            </div>
          </FormBox>
          <FormBox name='POD Page Details'>
            <Input
              name='Heading Intro'
              value={podData.introHeading}
              onChange={(e) => {
                setPodData((state) => ({
                  ...state,
                  introHeading: e.target.value,
                }));
                clearFieldErrors("introHeading");
              }}
              isInvalid={fieldErrors.introHeading}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Content Intro'
              value={podData.introContent}
              onChange={(e) => {
                setPodData((state) => ({
                  ...state,
                  introContent: e.target.value,
                }));
                clearFieldErrors("introContent");
              }}
              isInvalid={fieldErrors.introContent}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Guideline 1 Heading'
              value={podData.eventGuidelines[0]?.heading}
              onChange={(e) => guidelineHandler(e.target.value, true, 0)}
              className={classes.input}
            />
            <Input
              name='Guideline 1 Text'
              value={podData.eventGuidelines[0]?.text}
              onChange={(e) => guidelineHandler(e.target.value, false, 0)}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Guideline 2 Heading'
              value={podData.eventGuidelines[1]?.heading}
              onChange={(e) => guidelineHandler(e.target.value, true, 1)}
              className={classes.input}
            />
            <Input
              name='Guideline 2 Text'
              value={podData.eventGuidelines[1]?.text}
              onChange={(e) => guidelineHandler(e.target.value, false, 1)}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Guideline 3 Heading'
              value={podData.eventGuidelines[2]?.heading}
              onChange={(e) => guidelineHandler(e.target.value, true, 2)}
              className={classes.input}
            />
            <Input
              name='Guideline 3 Text'
              value={podData.eventGuidelines[2]?.text}
              onChange={(e) => guidelineHandler(e.target.value, false, 2)}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Guideline 4 Heading'
              value={podData.eventGuidelines[3]?.heading}
              onChange={(e) => guidelineHandler(e.target.value, true, 3)}
              className={classes.input}
            />
            <Input
              name='Guideline 4 Text'
              value={podData.eventGuidelines[3]?.text}
              onChange={(e) => guidelineHandler(e.target.value, false, 3)}
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
            <Input
              name='Welcome Text'
              value={podData.welcomeText}
              onChange={(e) =>
                setPodData((state) => ({
                  ...state,
                  welcomeText: e.target.value,
                }))
              }
              textarea
              className={classes.input}
              inputClassName={classes.textarea}
            />
          </FormBox>
          <FormBox name='Info Questions'>
            <div className={classes.tableContainer}>
              {selectedInfoQuestions.length ? (
                <Table
                  columns={infoQuestionTableColumn}
                  data={selectedInfoQuestions}
                />
              ) : (
                <div className={classes.noSelected}>
                  No info question selected
                </div>
              )}
            </div>
            <div className={classes.infoContainer}>
              <LinkButton
                label={"Change Ordering"}
                // icon={<img src={addIcon} alt='' />}
                onClick={() => setShowChangeOrderingModal(true)}
              />
              <LinkButton
                label={"Add Question"}
                icon={<img src={addIcon} alt='' />}
                onClick={() => setShowInfoQuestionsModal(true)}
              />
            </div>
          </FormBox>
          <FormBox name='Disclaimers'>
            <div className={classes.tableContainer}>
              {selectedDisclaimers.length ? (
                <Table
                  columns={disclaimerTableColumn}
                  data={selectedDisclaimers}
                />
              ) : (
                <div className={classes.noSelected}>No disclaimer selected</div>
              )}
            </div>
            <div className={classes.infoContainer}>
              <LinkButton
                label={"Add Disclaimer"}
                icon={<img src={addIcon} alt='' />}
                onClick={() => setShowDisclaimersModal(true)}
              />
            </div>
          </FormBox>
          <FormBox name='Landing Page'>
            <div className={classes.landingPageContainer}>
              <div>
                <Input
                  name='Start Date'
                  value={getLocalDateTimeString(
                    podData?.landingPage?.startTime ?? new Date().getTime()
                  )}
                  type='datetime-local'
                  onChange={(e) => {
                    const timestamp = getTimestampFromDateValue(e.target.value);
                    setPodData((prevState) => {
                      return {
                        ...prevState,
                        landingPage: {
                          ...prevState.landingPage,
                          startTime: timestamp,
                        },
                      };
                    });
                    if (
                      timestamp >
                      (podData?.landingPage?.endTime || new Date().getTime())
                    ) {
                      setPodData((prevState) => {
                        return {
                          ...prevState,
                          landingPage: {
                            ...prevState.landingPage,
                            endTime: timestamp,
                          },
                        };
                      });
                    }
                  }}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='End Date'
                  value={getLocalDateTimeString(
                    podData?.landingPage?.endTime || new Date().getTime()
                  )}
                  type='datetime-local'
                  onChange={(e) => {
                    setPodData((prevState) => {
                      return {
                        ...prevState,
                        landingPage: {
                          ...prevState.landingPage,
                          endTime: getTimestampFromDateValue(e.target.value),
                        },
                      };
                    });
                  }}
                  className={classes.input}
                  min={getLocalDateTimeString(podData?.landingPage?.startTime)}
                />
              </div>
              <div>
                <Select
                  label='Select Timezone'
                  htmlFor='timezone'
                  options={timezoneOptions}
                  nameExtractor='label'
                  valueExtractor='value'
                  value={timezone.value}
                  onChange={(e) =>
                    setTimezone(parseTimezone(e.currentTarget.value))
                  }
                  className={classes.input}
                />
              </div>
            </div>
            <div>
              <Input
                name='Landing Page URL'
                value={podData?.landingPage?.pageLink ?? ""}
                onChange={(e) => {
                  setPodData((prevState) => {
                    return {
                      ...prevState,
                      landingPage: {
                        ...prevState.landingPage,
                        pageLink: e.target.value,
                      },
                    };
                  });
                }}
                className={classes.input}
              />
            </div>
          </FormBox>
          <FormBox name='Upload Image'>
            <div className={classes.uploadImageContainer}>
              <DragDropField
                name='Landing Page Image'
                onChange={(file: any) => {
                  setHeroImageFile(file);
                }}
                fileTypes={["png", "jpg", "jpeg"]}
                selected={!!heroImageFile}>
                <img src={heroImageUrl} className={classes.uploadImg} alt='' />
              </DragDropField>
              <DragDropField
                name='POD Logo Image'
                onChange={(file: any) => {
                  setLogoImageFile(file);
                }}
                fileTypes={["png", "jpg", "jpeg"]}
                selected={!!logoImageFile}>
                <img
                  src={logoImageUrl}
                  className={getClasses(
                    logoImageUrl ? classes.uploadLogoImg : classes.logoImage
                  )}
                  alt=''
                />
              </DragDropField>
            </div>
          </FormBox>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={`${podToBeEdited.id ? "Update" : "Create New"} POD`}
              onClick={() => addPODHandler(!!podToBeEdited.id)}
              style={{ marginRight: "1rem" }}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={handleBack}
              transparent
            />
          </div>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default CreatePOD;
