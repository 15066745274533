import { useEffect, useState } from "react";
import { Column } from "../../../ts/types";
import { fetchInfoQuestions } from "../../../services/masters";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import InfoQuestion from "../../../ts/models/InfoQuestion";
import DragDropTable from "../../../components/CustTable/DragDropTable";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import classes from "./InfoQuestionsOrdering.module.css";

type Props = {
  items: InfoQuestion[];
  onClose: () => void;
  setItemsHandler: (items: Set<string>) => void;
};

const InfoQuestionsOrdering: React.FC<Props> = ({
  items,
  onClose,
  setItemsHandler,
}) => {
  const [tableData, setTableData] = useState(items);
  const [selectedInfoQuestions, setSelectedInfoQuestions] = useState<
    InfoQuestion[]
  >([]);

  useEffect(() => {
    const fetchInfoQuestionsFromIds = async () => {
      const logicQuestions = items.filter((data) => data.logicQuestion);
      const infoQuestionIds = logicQuestions.map((infoQues) => ({
        $oid: infoQues.logicQuestion,
      }));

      if (infoQuestionIds?.length) {
        const filter = {
          where: {
            _id: {
              $in: infoQuestionIds,
            },
          },
        };

        try {
          const infoQuestionRes = await fetchInfoQuestions(filter);
          if (infoQuestionRes.status) {
            setSelectedInfoQuestions(infoQuestionRes.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setSelectedInfoQuestions([]);
      }
    };

    fetchInfoQuestionsFromIds();
  }, []);

  const getQuestionById = (questionId?: string) => {
    return selectedInfoQuestions.find((question) => question.id === questionId);
  };

  const columns: Column<InfoQuestion>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx + 1,
      width: "15%",
    },
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "SHOWN WHEN",
      accessor: (e) => getQuestionById(e.logicQuestion)?.question || "",
    },
    {
      header: "RESPONSE IS",
      accessor: (e) => e.logicQuestionResponse || "",
    },
  ];

  const itemsHandler = () => {
    const itemIdsSet = new Set(
      tableData.map((infoQuestion) => infoQuestion.id)
    );
    setItemsHandler(itemIdsSet);
    onClose();
  };

  return (
    <Modal title={<>Change Info Questions Ordering</>} onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <DragDropTable
              columns={columns}
              data={tableData}
              setUpdateData={(infoQuestions: InfoQuestion[]) =>
                setTableData(infoQuestions)
              }
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton name='Ok' onClick={itemsHandler} />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={onClose}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default InfoQuestionsOrdering;
