import { ReactElement, useState } from "react";

import { Column } from "../../ts/types";
import classes from "./DragDropTable.module.css";
import { getClasses } from "../../utils";

interface CommonProps<T> {
  columns: Column<T>[];
  data: T[];
  setUpdateData: any;
}

type Props<T> = CommonProps<T>;

const DragDropTable = <T extends unknown>({
  columns,
  data,
  setUpdateData,
}: Props<T>): ReactElement => {
  const [tableData, setTableData] = useState(data);
  const [draggedRowIndex, setDraggedRowIndex] = useState<number | null>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggedRowIndex(index);
  };

  const handleDragOver = (event: React.DragEvent, index: number) => {
    event.preventDefault();
    setHoveredRowIndex(index);
  };

  // Handle drag leave to remove hover effect
  const handleDragLeave = () => {
    setHoveredRowIndex(null); // Clear the hovered row index
  };

  const handleDrop = (index: number) => {
    if (draggedRowIndex === null || draggedRowIndex === index) return;

    const updatedData = [...tableData];
    const draggedRow = updatedData[draggedRowIndex];
    updatedData.splice(draggedRowIndex, 1);
    updatedData.splice(index, 0, draggedRow);

    setTableData(updatedData);
    setUpdateData(updatedData);
    setDraggedRowIndex(null);
    setHoveredRowIndex(null);
  };

  return (
    <div className={classes.main}>
      <div className={classes.tableContainer}>
        <table>
          <thead className={classes.thead}>
            <tr>
              {columns.map((col, idx) => (
                <th style={{ width: col.width, ...col.style }} key={idx}>
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((ele, index) => (
              <tr
                className={getClasses(
                  classes.row,
                  hoveredRowIndex === index ? classes.rowHovered : ""
                )}
                key={index}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={() => handleDrop(index)}
                onDragLeave={handleDragLeave}
                style={{ cursor: "move" }}>
                {columns.map((col, idx) => (
                  <td style={col.style} key={idx}>
                    {col.accessor(ele, index)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DragDropTable;
